import React from "react";
import CloudDivider from "../CloudDivider";
import CustomUnderline from "../CustomUnderline";
import CustomTabs from "../CustomTabs";

const HomeSix = () => {
  return (
    <div className="home-6 bg-white">
      <CloudDivider color="#d1dff0" />
      <h3>
        <span>Our Classes</span>
      </h3>
      <div className="mb-20">
        <CustomUnderline />
      </div>
      <div className="container">
        <CustomTabs />
      </div>
    </div>
  );
};

export default HomeSix;
