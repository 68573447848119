//import { Button } from "antd";
import React from "react";
import CustomUnderline from "../CustomUnderline";
import RowFormat from "../RowFormat";

const HomeFive = () => {
  const left = (
    <div className="flex mb-40 mt-10">
      <div className="card shadow-1">
        <h4>Virtual class</h4>
        <p>
          Learn online with Expert Tutors 1-hour per class each program runs for
          8-10 class per course recommended for 6 years +
        </p>
        {/* <Button
          className="light-blue-btn"
          type="primary"
          shape="round"
          size="large"
        >
          Learn more
        </Button> */}
      </div>
    </div>
  );
  const left2 = (
    <div className="flex mb-40 mt-10">
      <div className="card shadow-1">
        <h4>Bootcamps</h4>
        <p>
          We organize bootcamps for accellerated learning during long public
          holidays and childrens vacation.
        </p>
        {/* <Button
          className="light-blue-btn"
          type="primary"
          shape="round"
          size="large"
        >
          Learn more
        </Button> */}
      </div>
    </div>
  );
  const right = (
    <div className="flex mb-40 mt-10">
      <div className="card shadow-1">
        <h4>Self Paced</h4>
        <p>
          This is designed to allow student learn own their own with little to
          no help. We provide instructional videos.(coming soon)
        </p>
        {/* <Button
          className="light-blue-btn"
          type="primary"
          shape="round"
          size="large"
        >
          Learn more
        </Button> */}
      </div>
    </div>
  );
  const right2 = (
    <div className="flex mb-40 mt-10">
      <div className="card shadow-1">
        <h4>Schools</h4>
        <p>
          We you like to introduce coding Clubs to your school? if yes look no
          further contact us today.
        </p>
        {/* <Button
          className="light-blue-btn"
          type="primary"
          shape="round"
          size="large"
        >
          Learn more
        </Button> */}
      </div>
    </div>
  );
  return (
    <div className="home-5 bg-light-blue">
      <h3>Our Classes</h3>
      <div className="mb-20">
        <CustomUnderline />
      </div>
      <p className="sub-title">
        You can enroll for any of the following classes  based on your own
        preference
      </p>
      <RowFormat className="home-1" left={left} right={right} />
      <RowFormat className="home-1" left={left2} right={right2} />
    </div>
  );
};

export default HomeFive;
