import React from "react";
import RowFormat from "../RowFormat";
import SplashDivider from "../SplashDivider";

const HomeThree = () => {
  const left = (
    <div className="flex mb-40 mt-10">
      <div>
        <h3 className="mb-20">Educator</h3>
        <p>
           Coding  involves logic, problem-solving, backwards planning, and
          other broadly applicable thinking skills, teachers of all subject
          areas Should consider making coding a part of their content area.
        </p>
        <p>
          Recent research also demonstrates positive links between learning
          computer science <em>with CS Fundamentals</em> and reading
          comprehension, problem-solving, and planning skills. 
        </p>
        <p>
          At Smart tech coders , we equip you with the necessary knowledge and
          skill required to teach coding  in the class room.
        </p>
        <p>
          No previous knowledge is required.  During our  training you should
          expect the following: 
        </p>
        <ol>
          <li>Learn new teaching strategy</li>
          <li>Experience curriculum adopted by most developed countries </li>
          <li> Practical Hands-on in coding problem solving.    </li>
        </ol>
      </div>
    </div>
  );
  const right = (
    <div className="flex mb-40 mt-10">
      <div>
        <img className="shadow-1" src="./edu.webp" alt="Education" />
      </div>
    </div>
  );

  return (
    <div className="home-3 bg-light-blue pb-30">
      <SplashDivider />
      <RowFormat className="home-1" left={left} right={right} invertSm={true} />
    </div>
  );
};

export default HomeThree;
