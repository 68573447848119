export const publicMenuItems = [
  { key: "home", type: "link", to: "/", name: "Home" },
  { key: "courses", type: "link", to: "/courses", name: "Courses" },
  // { key: "pricing", type: "link", to: "/pricing", name: "Pricing" },
  { key: "contact", type: "link", to: "/contact", name: "Contact Us" },
  {
    key: "login",
    type: "href",
    to: "https://class.smtcoders.com/login",
    name: "Login",
  },
];

export const coursesData = [
  {
    image: "./Codingj.webp",
    title: "Coding Junior (smart phone)",
    description: `<p>In this course, kids will be introduced to the Computer programming concept. They will also be guided to carry out projects.</p><ul><li>Sequence</li><li>Loops</li><li>Event</li><li>Condition</li></ul>`,
    venue: "online",
    duration: "4 Weeks",
    cost: 10000,
    payLink: "https://flutterwave.com/pay/we9p74f5a5mx",
  },
  {
    image: "./coding.webp",
    title: "Introduction to Coding",
    description: `<p>In this course, kids will be introduced to the Computer programming concept. They will also be guided to carry out projects.</p><ul><li>Sequence</li><li>Loops</li><li>Event</li><li>Condition</li></ul>`,
    venue: "online",
    duration: "6 Weeks",
    cost: 20000,
    payLink: "https://flutterwave.com/pay/cttihj01ekht",
  },
  {
    image: "./intermidiate.webp",
    title: "Introduction to Coding (Intermediate)",
    description: `<p>In this course, kids will learn about more Advance  beginner friendly programming concepts.</p><ul><li>Variable.</li><li> Functions.</li><li>Mathimatic Operators.</li><li>Logics</li></ul>`,
    venue: "online",
    duration: "6 Weeks",
    cost: 20000,
    payLink: "https://flutterwave.com/pay/mfbnd2attzmv",
  },
  {
    image: "./appmaking.webp",
    title: "App making with appiventor",
    description: `<p>In this course, kids will learn how to build Android apps using App Inventor.</p><ul><li>layout</li><li>Talking tom</li><li>publishing App on play Store</li></ul>`,
    venue: "online",
    duration: "6 Weeks",
    cost: 20000,
    payLink: "https://flutterwave.com/pay/kaeka6djqxj9",
  },
  {
    image: "./web.webp",
    title: "Introduction to web Development (HTML and CSS.)",
    description: `<p>In this course, the student will learn how to design website with html and CSS</p><ul><li>Tags</li><li>Layout</li><li>CSS Selector</li><li>File System</li></ul>`,
    venue: "online",
    duration: "4 Weeks",
    cost: 20000,
    payLink: "https://flutterwave.com/pay/lzgdumgsdyk1",
  },
];
