import React, { useContext } from "react";

import { AppContext } from "../../context/AppContext";

const Burger = () => {
  const { state, dispatch } = useContext(AppContext);
  const toggleBurger = () => {
    dispatch({ type: "setOpenBurger", payload: !state.openBurger });
  };

  return (
    <button className="fancy-burger show-burger" onClick={toggleBurger}>
      <span
        className={`rectangle rectangle--top rectangle--small${
          state.openBurger ? " open" : ""
        }`}
      ></span>
      <span
        className={`rectangle rectangle--middle${
          state.openBurger ? " open" : ""
        }`}
      ></span>
      <span
        className={`rectangle rectangle--bottom rectangle--small${
          state.openBurger ? " open" : ""
        }`}
      ></span>
    </button>
  );
};

export default Burger;
