import React, { useRef } from "react";
import CoursesAvailable from "../components/public/CoursesAvailable";
import CoursesHeroSection from "../components/public/CoursesHeroSection";
import Layout from "../components/public/Layout";

const Courses = () => {
  const coursesRef = useRef();
  const pageTitle = "Courses Page";
  const animateToCourses = () => {
    coursesRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Layout sendMark="courses" pageTitle={pageTitle}>
      <CoursesHeroSection animateToCourses={animateToCourses} />
      <CoursesAvailable coursesRef={coursesRef} />
    </Layout>
  );
};

export default Courses;
