import React from "react";
import parse from "html-react-parser";

import RowFormat from "../RowFormat";
import { coursesData } from "../../util/data";
import { formatCurrency } from "../../util";
import { Button } from "antd";

const CoursesAvailable = ({ coursesRef }) => {
  const handleLeft = ({ image }) => (
    <div className="flex pb-30">
      <img className="shadow-1" src={image} alt={image} />
    </div>
  );
  const handleRight = ({
    title,
    description,
    duration,
    venue,
    cost,
    payLink,
  }) => (
    <div className="flex pb-30">
      <div>
        <h3 className="mb-20">{title ? title : ""}</h3>
        {description ? parse(description) : ""}
        <div className="course-footer mt-10">
          <div className="c-f-divider">
            <div className="c-f-title">Duration</div>
            <div>{duration ? duration : ""}</div>
          </div>
          <div className="c-f-divider">
            <div className="c-f-title">Venue</div>
            <div>{venue ? venue : ""}</div>
          </div>
          <div className="c-f-divider">
            <div className="c-f-title">Cost</div>
            <div>{cost ? formatCurrency(cost) : ""}</div>
          </div>
        </div>
        <div className="text-center mt-20">
          <Button
            className="light-blue-btn"
            shape="round"
            size="large"
            href={payLink}
            type="primary"
          >
            Enroll Now
          </Button>
        </div>
      </div>
    </div>
  );
  let allCourses;
  if (coursesData && coursesData.length) {
    allCourses = coursesData.map((course, index) => {
      const left = handleLeft(course);
      const right = handleRight(course);
      return (
        <div className="mb-50" key={index}>
          <RowFormat className="home-1" left={left} right={right} />
        </div>
      );
    });
  }

  return (
    <div ref={coursesRef} className="home-2 courses pb-60 bg-white">
      {allCourses}
    </div>
  );
};

export default CoursesAvailable;
