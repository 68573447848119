import React from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";

const Thanks = () => {
  return (
    <div className="flex" style={{ height: "100vh" }}>
      <Result
        status="success"
        title="Thanks for the payment!"
        subTitle="An admin will get back to you on your credentials."
        extra={[
          <Link key="home" to="/">
            <Button type="primary">Home Page</Button>
          </Link>,
        ]}
      />
    </div>
  );
};

export default Thanks;
