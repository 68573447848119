import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";

const RowFormat = ({ left, right, invertSm }) => {
  return (
    <div className="container">
      <Row>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className={`col-left${invertSm ? " order-sm-2" : ""}`}
        >
          {left}
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className={`col-right${invertSm ? " order-sm-1" : ""}`}
        >
          {right}
        </Col>
      </Row>
    </div>
  );
};

RowFormat.propTypes = {
  left: PropTypes.element,
  right: PropTypes.element,
  invertSm: PropTypes.bool,
};

export default RowFormat;
