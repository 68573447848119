import React from "react";
import { Tabs } from "antd";
import { AppleOutlined, AndroidOutlined, BugOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const tabs = [
  {
    title: "Coding",
    icon: <AppleOutlined />,
    content:
      "Coding is at the core of what we do. The ability to give instructions to computers and robots teaches your kids to not just solve problems but to also foresee them.",
  },
  {
    title: "Robotics",
    icon: <AndroidOutlined />,
    content:
      "We enable kids with the concepts of how robots and similar machineries work. This gives them an edge over their peers in a world becoming ever reliant on machines.",
  },
  {
    title: "Artificial Intelligence",
    icon: <BugOutlined />,
    content:
      "We teach kids to design intelligent computer programs. This ability puts your child at the top of the demand hierarchy, globally.",
  },
];
const CustomTabs = () => {
  let tabContent;
  if (tabs && tabs.length) {
    tabContent = tabs.map((tab, index) => {
      return (
        <TabPane
          tab={
            <span>
              {tab.icon}
              {tab.title}
            </span>
          }
          key={++index}
        >
          {tab.content}
        </TabPane>
      );
    });
  }
  return (
    <div className="mt-40">
      <Tabs defaultActiveKey="1">{tabContent}</Tabs>
    </div>
  );
};

export default CustomTabs;
