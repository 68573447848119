import React from "react";
import HomeOne from "../components/public/HomeOne";
import HomeTwo from "../components/public/HomeTwo";
import HomeThree from "../components/public/HomeThree";
import HomeFour from "../components/public/HomeFour";
import HomeFive from "../components/public/HomeFive";
import HomeSix from "../components/public/HomeSix";
import Layout from "../components/public/Layout";

const Home = () => {
  const pageTitle = "Home Page";
  return (
    <Layout sendMark="home" pageTitle={pageTitle}>
      <HomeOne />
      <HomeTwo />
      <HomeThree />
      <HomeFour />
      <HomeFive />
      <HomeSix />
    </Layout>
  );
};

export default Home;
