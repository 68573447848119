import React from "react";
import { useImmerReducer } from "use-immer";

const initialState = {
  openBurger: false,
  scrolled: false,
  scrolledPass400: false,
  reload: 0,
  year: 2021,
  showMsg: {
    status: "",
    messages: [],
  },
};

const reducer = (draft, action) => {
  switch (action.type) {
    case "setLoggedIn":
      draft.loggedIn = action.payload;
      return;
    case "setReload":
      draft.reload = draft.reload + 1;
      return;
    case "setYear":
      draft.year = action.payload;
      return;
    case "setOpenBurger":
      draft.openBurger = action.payload;
      return;
    case "setScrolled":
      draft.scrolled = action.payload;
      return;
    case "setScrolledPass400":
      draft.scrolledPass400 = action.payload;
      return;
    case "setShowMsg":
      draft.showMsg.status = action.payload.status;
      draft.showMsg.messages = action.payload.messages;
      return;
    case "resetShowMsg":
      draft.showMsg.status = "";
      draft.showMsg.messages = [];
      return;
    case "reset":
      return initialState;
    default:
      return initialState;
  }
};

export const AppContext = React.createContext(initialState);

export const AppProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
