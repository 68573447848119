import React from "react";
import { PhoneFilled, MailFilled } from "@ant-design/icons";

import RowFormat from "../components/RowFormat";
import SplashDivider from "../components/SplashDivider";
import Layout from "../components/public/Layout";
import ContactForm from "../components/public/ContactForm";

const Contact = () => {
  const left = (
    <div className="flex mb-40 home-1-left">
      <div>
        <h1 className="mb-20 text-light-blue">Contact Us</h1>
        <p className="mb-20">We'ld love to hear from you.</p>
        <div className="contact-icons">
          <ul>
            <li>
              <a href="mailto:hi@smtcoders.com">
                <span className="contact-icon">
                  <MailFilled />
                </span>
                <span className="contact-val">hi@smtcoders.com</span>
              </a>
            </li>
            <li>
              <a href="callto:+2348034889229">
                <span className="contact-icon">
                  <PhoneFilled />
                </span>
                <span className="contact-val">+2348034889229</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
  const right = (
    <div className="mb-40">
      <ContactForm />
    </div>
  );
  const pageTitle = "Contact Page";
  return (
    <Layout sendMark="contact" pageTitle={pageTitle}>
      <div className="home-1 bg-blue">
        <RowFormat left={left} right={right} />
        <div style={{ marginBottom: "50px" }}>
          <SplashDivider className="rotate" />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
