import React, { useEffect, useContext } from "react";
import PropsType from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import MobileDrawer from "./MobileDrawer";
import { resetScrollY } from "../../util";
import { AppContext } from "../../context/AppContext";

const Layout = ({ children, sendOpen, sendMark, pageTitle }) => {
  const { state, dispatch } = useContext(AppContext);
  useEffect(() => {
    pageTitle && (window.document.title = `SmartTechCoders || ${pageTitle}`);
    state.openBurger && dispatch({ type: "setOpenBurger", payload: false });
    resetScrollY();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Header />
      <MobileDrawer sendOpen={sendOpen} sendMark={sendMark} />
      <main className="content-area">
        <div className="content">{children}</div>
        <Footer />
      </main>
    </>
  );
};
Layout.propType = {
  children: PropsType.element,
  sendOpen: PropsType.string,
  sendMark: PropsType.string,
  pageTitle: PropsType.string,
};
export default Layout;
