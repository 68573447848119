import React, { useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { Button } from "antd";
import Burger from "./Burger";
import { AppContext } from "../../context/AppContext";
import { publicMenuItems } from "../../util/data";

const Header = () => {
  const { state } = useContext(AppContext);

  let li;
  if (publicMenuItems && publicMenuItems.length) {
    li = publicMenuItems.map((item) => {
      const link =
        item.type === "href" ? (
          <a href={item.to} target="_blank" rel="noreferrer">
            <Button
              type="default"
              shape="round"
              size="large"
              className="nav-login-btn"
            >
              {item.name}
            </Button>
          </a>
        ) : (
          <NavLink exact to={item.to}>
            {item.name}
          </NavLink>
        );
      return (
        <li key={item.key} className="link">
          {link}
        </li>
      );
    });
  }

  return (
    <header className={`header ${state.scrolled ? " scrolled" : ""}`}>
      <div className="container">
        <nav>
          <Link to="/">
            <img src="./logo.png" alt="Official Logo" />
          </Link>
          <div className="nav-right">
            <ul>
              {li}
              <Burger />
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
