import React, { useState, useRef } from "react";
import Axios from "axios";
import { Button, Form, Input, notification, message } from "antd";

const ContactForm = () => {
  const formRef = useRef();
  const [sending, setSending] = useState(false);
  const openNotification = (type, title, desc) => {
    let notify;
    if (type === "success") {
      notify = notification.success;
    } else if (type === "error") {
      notify = notification.error;
    }
    notify({
      message: title,
      description: desc,
      duration: 10,
    });
  };
  const onFinish = async (data) => {
    try {
      setSending(true);
      const response = await Axios({
        method: "post",
        url: "/contact",
        crossDomain: true,
        data,
      });
      if (response && response.data && response.data.success) {
        openNotification(
          "success",
          "Message Sent",
          "We have received your message and we will reply through the email address you provided. Thank!"
        );
        formRef.current.resetFields();
        setSending(false);
      } else {
        throw new Error("Server error");
      }
    } catch (error) {
      setSending(false);
      message.error("Message could not be sent");
      console.log(error);
    }
  };
  return (
    <div className="contact">
      <Form
        ref={formRef}
        layout="vertical"
        autoComplete="off"
        name="normal_login"
        className="login-form"
        onFinish={onFinish}
      >
        <Form.Item
          label="Your Name"
          style={{ marginBottom: "15px" }}
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter your name",
            },
          ]}
        >
          <Input
            autoComplete="off"
            size="large"
            placeholder="Name"
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          label="Email"
          style={{ marginBottom: "15px" }}
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your E-mail!",
            },
            {
              type: "email",
              message: "Please  enter a valid E-mail!",
            },
          ]}
        >
          <Input
            autoComplete="off"
            size="large"
            placeholder="Email"
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          label="Phone No"
          style={{ marginBottom: "15px" }}
          name="number"
        >
          <Input
            autoComplete="off"
            size="large"
            placeholder="Phone Number"
            maxLength={20}
          />
        </Form.Item>
        <Form.Item
          label="Subject"
          style={{ marginBottom: "15px" }}
          name="subject"
          rules={[
            {
              required: true,
              message: "Please enter the subject title",
            },
          ]}
        >
          <Input
            autoComplete="off"
            size="large"
            placeholder="Subject"
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          label="Message"
          style={{ marginBottom: "15px" }}
          name="message"
          rules={[
            {
              required: true,
              message: "Please enter the message",
            },
          ]}
        >
          <Input.TextArea
            autoComplete="off"
            size="large"
            placeholder="message"
            showCount
            maxLength={500}
            autoSize
          />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form-button mt-10"
            loading={sending ? true : false}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactForm;
