import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Result, Button } from "antd";

const PageNotFound = () => {
  let history = useHistory();
  return (
    <div className="flex" style={{ height: "100vh" }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={[
          <Link key="home" to="/">
            <Button type="primary">Home Page</Button>
          </Link>,
          <Button key="back" onClick={() => history.goBack()}>
            Go Back
          </Button>,
        ]}
      />
    </div>
  );
};

export default PageNotFound;
