import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import { Button } from "antd";
import { ArrowDownOutlined } from "@ant-design/icons";
import RowFormat from "../RowFormat";
import SplashDivider from "../SplashDivider";
import animationData from "../../girl.json";

const CoursesHeroSection = ({ animateToCourses }) => {
  const container = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData,
    });
  }, []);
  const left = (
    <div className="flex mb-40 home-1-left">
      <div>
        <h1 className="mb-20 text-light-blue">Available</h1>
        <h1 className="mb-20 text-gold">Courses</h1>
        <p className="mb-30">
          Your kids will love learning to code while building video games.
        </p>
        <Button
          onClick={() => animateToCourses()}
          type="primary"
          size="large"
          shape="round"
        >
          View Courses{" "}
          <i className="ml-10">
            <ArrowDownOutlined />
          </i>
        </Button>
      </div>
    </div>
  );
  const right = (
    <div className="flex mb-40">
      <div ref={container}></div>
    </div>
  );

  return (
    <div className="home-1 bg-blue">
      <RowFormat left={left} right={right} invertSm={true} />
      <SplashDivider className="rotate" />
    </div>
  );
};

export default CoursesHeroSection;
