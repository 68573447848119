import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Menu } from "antd";

import { AppContext } from "../../context/AppContext";
import { publicMenuItems } from "../../util/data";

const MobileMenu = ({ open, mark }) => {
  const { dispatch } = useContext(AppContext);
  let content;
  if (publicMenuItems && publicMenuItems.length) {
    content = publicMenuItems.map((item) => {
      const link =
        item.type === "href" ? (
          <a href={item.to} target="_blank" rel="noreferrer">
            {item.name}
          </a>
        ) : (
          <Link to={item.to}>{item.name}</Link>
        );
      return <Menu.Item key={item.key}>{link}</Menu.Item>;
    });
  }
  const handleClick = (e) => {
    if (e.key === "login") {
      dispatch({ type: "setOpenBurger", payload: false });
    }
  };
  return (
    <div>
      <Menu
        style={{ width: "100%" }}
        defaultSelectedKeys={[mark]}
        defaultOpenKeys={[open]}
        mode="inline"
        onClick={handleClick}
      >
        {content}
      </Menu>
    </div>
  );
};

MobileMenu.propTypes = {
  open: PropTypes.string,
  mark: PropTypes.string,
};
export default MobileMenu;
