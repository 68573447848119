import React, { useContext } from "react";
import { Drawer } from "antd";
import MobileMenu from "./MobileMenu";
import { AppContext } from "../../context/AppContext";

const MobileDrawer = ({ sendOpen, sendOpen2, sendMark }) => {
  const { state, dispatch } = useContext(AppContext);
  const onClose = () => {
    dispatch({ type: "setOpenBurger", payload: false });
  };
  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={state.openBurger}
      className="mobile-drawer"
      zIndex={99}
    >
      <MobileMenu open={sendOpen} open2={sendOpen2} mark={sendMark} />
    </Drawer>
  );
};

export default MobileDrawer;
