import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import RowFormat from "../RowFormat";
import WaveDivider from "../WaveDivider";

const HomeOne = () => {
  const left = (
    <div className="flex mb-40 home-1-left">
      <div>
        <h1 className="mb-20 text-light-blue">We make</h1>
        <h1 className="mb-20 text-gold">coding fun!</h1>
        <p className="mb-30">
          Your kids will love learning to code while building video games.
        </p>
        <Link to="/courses">
          <Button type="primary" size="large" shape="round">
            Enroll Now{" "}
            <i className="ml-10">
              <ArrowRightOutlined />
            </i>
          </Button>
        </Link>
      </div>
    </div>
  );
  const right = (
    <div className="flex mb-40">
      <img src="./pencil.webp" alt="Pencil swing" />
    </div>
  );
  return (
    <div className="home-1 bg-blue">
      <RowFormat left={left} right={right} invertSm={true} />
      <WaveDivider className="rotate" />
    </div>
  );
};

export default HomeOne;
