import React from "react";
import RowFormat from "../RowFormat";
import SplashDivider from "../SplashDivider";

const HomeFour = () => {
  const left = (
    <div className="flex mb-40 mt-10">
      <div>
        <img className="shadow-1" src="./parents.webp" alt="Parents" />
      </div>
    </div>
  );
  const right = (
    <div className="flex mb-40 mt-10">
      <div>
        <h3 className="mb-20">Parents</h3>
        <p>
          Coding is quickly becoming an essential skill, one that will prepare
          your kids to succeed in an increasingly digital world.
        </p>
        <p>
          Smart tech Coders offers a range of options to learn coding: Your
          children can learn by themselves with our self-paced, immersive
          game-like courses(coming soon) or learn with a certified expert
          in group classes. They can even learn privately with a personal tutor!
        </p>
        <p>Select the option that’s best for your child!</p>
      </div>
    </div>
  );

  return (
    <div className="home-3 bg-white pb-70">
      <SplashDivider color="#d1dff0" />
      <RowFormat className="home-1" left={left} right={right} />
    </div>
  );
};

export default HomeFour;
