import React from "react";
import Layout from "../components/public/Layout";

const Pricing = () => {
  return (
    <Layout sendMark="pricing">
      <div>Pricing</div>
    </Layout>
  );
};

export default Pricing;
