import React from "react";
import { BackTop } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";

function Footer() {
  return (
    <>
      <div className="footer">
        <p>© 2021, Smarttechcoders</p>
      </div>
      <BackTop>
        <ArrowUpOutlined className="go-top" />
      </BackTop>
    </>
  );
}

export default Footer;
