import { useEffect, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { AppContext } from "./context/AppContext";
import "./App.less";

import Home from "./pages/Home";
import Courses from "./pages/Courses";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/PageNotFound";
import Thanks from "./pages/Thanks";

function App() {
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    window.addEventListener("scroll", getScrollPosition);
    return () => window.removeEventListener("scroll", getScrollPosition);
    // eslint-disable-next-line
  }, []);

  const getScrollPosition = () => {
    window.pageYOffset >= 200
      ? dispatch({ type: "setScrolled", payload: true })
      : dispatch({ type: "setScrolled", payload: false });
    window.pageYOffset > 400
      ? dispatch({ type: "setScrolledPass400", payload: true })
      : dispatch({ type: "setScrolledPass400", payload: false });
  };

  useEffect(() => {
    // Remove preloader
    setTimeout(() => {
      const preloader = document.querySelector(".site-preloader");

      preloader.addEventListener("transitionend", (event) => {
        if (event.propertyName === "opacity") {
          preloader.parentNode.removeChild(preloader);
        }
      });
      preloader.classList.add("site-preloader__fade");
    }, 500);
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/courses" component={Courses} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/thank-you" component={Thanks} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
}

export default App;
