import React from "react";
import RowFormat from "../RowFormat";

const HomeTwo = () => {
  const left = (
    <div className="flex mb-40">
      <img src="./girl.webp" alt="Girl" />
    </div>
  );
  const right = (
    <div className="flex mb-40">
      <div>
        <h3 className="mb-20">The #1 Coding Platform for Kids</h3>
        <p>
          Because <b>technology is so integrated</b> into virtually every aspect
          of our lives, learning about it is <b>more important than ever</b>.At
          smart Tech Coders,  our coding platform makes it convenient and fun
          for <strong> Kid </strong> to gain knowledge that will serve them well
          throughout their lives. You can count on our <b>trusted methods</b> to
          help them develop and grow their capabilities with computers.
        </p>
        <p>
          We offer both virtual classes and self paced learning for both
          Educators and kids.
        </p>
      </div>
    </div>
  );

  return (
    <div className="home-2 bg-white">
      <RowFormat className="home-1" left={left} right={right} />
    </div>
  );
};

export default HomeTwo;
