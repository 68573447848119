import React from "react";
import PropsType from "prop-types";

const CustomUnderline = ({ color }) => {
  return (
    <div className="underline">
      <span
        style={{ backgroundColor: color ? color : "" }}
        className="underline-dot"
      ></span>
      <span
        style={{ backgroundColor: color ? color : "" }}
        className="underline-dot"
      ></span>
      <span
        style={{ backgroundColor: color ? color : "" }}
        className="underline-dot"
      ></span>
      <span
        style={{ backgroundColor: color ? color : "" }}
        className="underline-line"
      ></span>
    </div>
  );
};

CustomUnderline.propTypes = {
  color: PropsType.string,
};

export default CustomUnderline;
